import { usePermissions } from 'ra-core';

// eslint-disable-next-line import/prefer-default-export
export function useIsAdmin(): boolean {
  const { permissions } = usePermissions();

  return (
    permissions?.includes('ADMIN') ||
    (process.env.NODE_ENV === 'development' &&
      permissions?.includes('Cognito_RESAAdminAuth_Role'))
  );
}
