import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Button } from 'react-admin';
import { Form, useFormState } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { ASSET_MAIL_NAMESPACE } from '../../../aws/cpret-config';
import { Borrower, Guarantee, Loan, GuarantyLoan } from '../../../types/schema';
import { AssetsField } from '../../../components/Fields/asset/AssetsField';
import { TemplatesProvider } from '../../../context/templatesContext';
import { CreatePostmailDialog } from './CreatePostmailDialog';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      width: '100%',
      flexGrow: 0,
    },
  }),
);

export interface PostmailDialogProps {
  record: Borrower | Loan | Guarantee | GuarantyLoan;
  templatePrefix: string;
  loading?: boolean;
}

export const PostmailDialog: React.FC<PostmailDialogProps> = ({
  record,
  templatePrefix,
  loading = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = React.useCallback(() => setOpen(!open), [open]);
  const classes = useStyles();
  const parentFormState = useFormState();

  return (
    <>
      <Button
        color="default"
        variant="contained"
        size="medium"
        label="Voir les courriers"
        onClick={toggleOpen}
      />
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Les courriers</DialogTitle>
        {loading && (
          <div className={classes.loader}>
            <LinearProgress />
          </div>
        )}
        <Form
          onSubmit={async () => null}
          initialValues={record}
          mutators={{ ...arrayMutators }}
        >
          {() => (
            <>
              <DialogContent>
                <AssetsField
                  namespace={ASSET_MAIL_NAMESPACE}
                  readonly
                  source="attachments"
                  showCreatedAt
                  record={record}
                />
              </DialogContent>
              <DialogActions>
                <TemplatesProvider>
                  <CreatePostmailDialog
                    record={record}
                    templatePrefix={templatePrefix}
                    disabled={!parentFormState.pristine}
                  />
                </TemplatesProvider>
                <Button
                  color="default"
                  variant="contained"
                  size="medium"
                  label="Fermer"
                  onClick={toggleOpen}
                  type="button"
                />
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};
