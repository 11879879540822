import gql from 'graphql-tag';
import { HttpError } from 'react-admin';

import _orderBy from 'lodash/orderBy';
import _memoize from 'lodash/memoize';

import { RefreshableCredentials } from 'phileog-login';
import awsExports from 'phicomas-client/src/projects/cpret/apolloClient/aws-exports';
import nodesBasicInfos from 'phicomas-client/src/projects/cpret/apolloClient/nodesBasicInfos.json';
import introspectionPossibleTypes from 'phicomas-client/src/projects/cpret/apolloClient/possibleTypes.json';
import {
  Level,
  FRAGMENT_FULL_SUFFIX,
  ID,
  Node,
  NodeInput,
  QueryNodes,
  getNodeUpdateFragment,
  CreateNodeVariables,
  MutationCreateNode,
  UpdateNodeVariables,
  MutationUpdateNode,
  DeleteNodeVariables,
  MutationDeleteNode,
  updateStore,
  getApolloClient,
  Environment,
} from 'phicomas-client';

import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  UpdateManyResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  Identifier,
} from 'ra-core';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import projectInfos, { ResourceKey } from '../project/projectInfos';

import getObservable from './observable';

import { transformCreatedData, transformUpdatedData } from './utils';

import filterNodes from './filters';

import withObserver from './withObserver';

import { CustomDataProvider, LedgerDigest } from './type';
import { awsEnv as defaultAwsEnv } from '../auth/authProvider';
import { calculatedFields } from './cpretCalculatedFields';
import { AppContextType } from '../context/AppContext';
import { fetchDigests } from './ledgerDigest';
import { Borrower, Owner } from '../types/schema';

const { resourcesInfos } = projectInfos;

export const PER_PAGE_FULL_LIST = -1;
export const DEFAULT_GET_LIST_PARAMS: GetListParams = {
  filter: {},
  pagination: { page: 1, perPage: 25 },
  sort: { field: 'id', order: 'DESC' },
};

type NodeArray = Array<QueryNodes<Node>['string']>;
type NonNullNodeArray = Array<NonNullable<NodeArray[number]>>;
function hasOnlyNonNullNodes(nodes: NodeArray): nodes is NonNullNodeArray {
  return nodes.every(d => d !== null);
}

type DataProviderOptions = {
  awsCredentials: AWS.Credentials;
  /** awsenv passes instance which selects roles in awsExports
   * @todo externalize assumedRoles (move to awsCredentials), or
   * simply pass assumedRole
   */
  awsEnv?: Environment;
  mutationFetchPolicy?: MutationFetchPolicy;
};

const memoFetchDigest = _memoize(
  (bb, args: Parameters<typeof fetchDigests>[0]) => {
    return fetchDigests(args);
  },
  (bb: Borrower[]) => {
    // const h = crypto.createHash('md5');
    // bb.sort(({ id: idA }, { id: idB }) => idA.localeCompare(idB)).forEach(b => {
    //   h.update(b.id);
    // });
    // return h.digest('hex');
    return JSON.stringify(
      // really ?!!
      bb
        .map(b => b.id)
        .sort((a, b) => a.localeCompare(b))
        .slice(0, 10),
    );
  },
);

// FIXME - This should be a class or a hook ...
export const getDataProvider = (
  environmentLevel: Level,
  {
    awsCredentials,
    awsEnv = defaultAwsEnv,
    mutationFetchPolicy,
  }: DataProviderOptions,
  setLoading: React.Dispatch<React.SetStateAction<AppContextType>> = () => {},
): CustomDataProvider => {
  // We need to fix getApolloClient to never call getAwsCredentials !
  // it should be able to change role, but not credentials
  // in the mean time, we forbid environment change
  // issue to be tracked here https://github.com/phileog/phicomas-client/issues/7
  if (environmentLevel !== awsEnv.level)
    throw new Error('Env change not supported !');
  // overriding getProjectApolloClient
  const apolloClient = getApolloClient(
    {
      awsCredentials,
      awsExports,
      nodesBasicInfos,
      possibleTypes: introspectionPossibleTypes,
    },
    awsEnv,
  );

  const checkAuth = async () => {
    if (
      awsCredentials instanceof RefreshableCredentials &&
      awsCredentials.needsRefresh()
    ) {
      const err = await awsCredentials.retry();
      if (err) {
        throw new HttpError(`Unauthorized`, '401');
      }
    }
  };

  const digestCache = new Map<Identifier, LedgerDigest>();

  const enhanceResult = (r: Node) => ({
    ...r,
    ...calculatedFields(r, digestCache),
  });

  const enhanceOne = <
    T extends
      | GetOneResult<Node>
      | CreateResult<Node>
      | UpdateResult<Node>
      | DeleteResult<Node>,
  >(
    res: T,
  ): T => ({
    ...res,
    data: res.data && enhanceResult(res.data),
  });

  const enhanceMany =
    (resource: ResourceKey) =>
    async <T extends GetListResult<Node> | GetManyResult<Node>>(
      res: T,
    ): Promise<T> => {
      // console.time('enhanceMany');
      let brwrs: Borrower[] | undefined;
      if (resource === 'cpretOwner') {
        brwrs = ([] as Borrower[]).concat(
          ...res.data.map(oNode => {
            const owner = oNode as Owner;
            return owner.borrowers?.edges.map(
              ({ node: bNode }) => bNode as Borrower,
            );
          }),
        );
      } else if (resource === 'cpretBorrower') {
        brwrs = res.data as Borrower[];
      }
      if (brwrs) {
        const bb = brwrs.filter(b => !digestCache.has(b.id));
        if (bb.length) {
          setLoading(({ loading, ...rest }) => ({
            ...rest,
            loading: loading + 1,
          }));
          (
            await memoFetchDigest(bb, {
              brwrs: bb,
              client: apolloClient,
            })
          ).forEach(([id, digest]) => {
            digestCache.set(id, digest);
          });
          setLoading(({ loading, ...rest }) => ({
            ...rest,
            loading: loading - 1,
          }));
        }
      }

      const result = {
        ...res,
        data: res.data.map(enhanceResult),
      };
      // console.timeEnd('enhanceMany');
      return result;
    };

  let dataProvider: CustomDataProvider;

  const baseDataProvider = {
    // Search for resource
    getList: async (
      resource: ResourceKey,
      params: GetListParams,
    ): Promise<GetListResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.getList', { resource, /* resourceInfos, */ params });
      const {
        query: { allName: queryAllName },
      } = resourceInfos;

      await checkAuth();
      const [obsQuery, obsQueryInit] = dataProvider.getObservable(resource);
      const queryRes = obsQueryInit.then(
        () => obsQuery.getCurrentResult(),
        () => obsQuery.getCurrentResult(),
      );

      return queryRes
        .then(response => {
          if (response.error) throw response.error;
          if (response.partial) {
            console.warn(obsQuery);
            throw new Error('Partial data detected!');
          }
          const unfilteredData = response.data[queryAllName].edges.map(
            ({ node }: { node: Node }) => node,
          );

          return { data: unfilteredData };
        })
        .then(enhanceMany(resource))
        .then(({ data: unfilteredData }) => {
          const { filter, pagination, sort } = params;

          const unsortedData = filterNodes(resource, unfilteredData, filter);

          const { field, order } = sort;
          const unpaginatedData = _orderBy(
            unsortedData,
            field,
            order.toLowerCase() as 'asc' | 'desc',
          );

          const { page, perPage } = pagination;
          const data =
            perPage !== PER_PAGE_FULL_LIST
              ? unpaginatedData.slice((page - 1) * perPage, page * perPage)
              : unpaginatedData;
          const res = { data, total: unpaginatedData.length };
          return res;
        })
        .then(res => {
          console.info('dp.getList result' /* , res */);
          return res;
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          throw new HttpError(`${e.name}: ${e.message}`, '500', e.stack);
        });
    },

    // Search for full resource
    getListAll: (resource: ResourceKey): Promise<GetListResult> => {
      console.info('dp.getListAll', { resource });
      return baseDataProvider
        .getList(resource, {
          ...DEFAULT_GET_LIST_PARAMS,
          pagination: { page: 1, perPage: PER_PAGE_FULL_LIST },
        })
        .then(res => {
          console.info('dp.getListAll result' /* , res */);
          return res;
        });
    },

    // Read a single resource, by id
    getOne: async (
      resource: ResourceKey,
      params: GetOneParams,
    ): Promise<GetOneResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.getOne', { resource, /* resourceInfos, */ params });

      const {
        query: { name: queryName },
        fragments: { name: fragmentName, full: fragmentFull },
      } = resourceInfos;
      const { id } = params;

      if (!id) return Promise.resolve({ data: null as unknown as Node });

      const q = gql`
        query getOne($id: ID!) {
          ${queryName}(id: $id) {
            ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
          }
        }
        ${fragmentFull}
      `;
      const variables = { id };

      await checkAuth();
      return apolloClient
        .query<QueryNodes>({
          query: q,
          variables,
          fetchPolicy:
            resource === 'cpretBorrower' ? 'cache-first' : 'no-cache',
        })
        .then(async response => {
          const data = response.data[queryName];
          if (data === null) {
            return Promise.reject(Error('dp.getOne got a null node'));
          }
          if (resourceInfos.connections)
            Object.keys(resourceInfos.connections).forEach(c => {
              const { pageInfo } = (data[c] as any) || {};
              if (pageInfo?.hasNextPage)
                console.warn(`FIXME - Connection ${c} has next page!`);
              if (pageInfo?.hasPreviousPage)
                console.warn(`FIXME - Connection ${c} has previous page!`);
            });
          return { data };
        })
        .then(enhanceOne)
        .then(res => {
          console.info('dp.getOne result' /* , res */);
          return { ...res, validUntil: new Date(Date.now() + 500) };
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          console.warn(e);
          // small cache to allow for withObserver call
          throw new HttpError(`${e.name}: ${e.message}`, '500', e.stack);
        });
    },

    // Read many entries of a resource, by ids
    getMany: async (
      resource: ResourceKey,
      params: GetManyParams,
    ): Promise<GetManyResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.getMany', { resource, resourceInfos, params });

      const {
        query: { name: queryName },
        fragments: { name: fragmentName, full: fragmentFull },
      } = resourceInfos;
      const { ids } = params as {
        ids: ID[];
      };

      if (ids.length > 100)
        throw new Error('FIXME - Cannot fetch more than 100!');

      const queries = ids.reduce(
        (acc: string, id, index) =>
          acc.concat(`
        ${queryName}${index}: ${queryName}(id: "${id}") {
          ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
        }
      `),
        '',
      );

      await checkAuth();
      return apolloClient
        .query<QueryNodes>({
          query: gql`
        query getMany {
          ${queries}
        }
        ${fragmentFull}
      `,
          fetchPolicy:
            resource === 'cpretBorrower' ? 'cache-first' : 'no-cache',
        })
        .then(response => {
          const data = Object.values(response.data ?? {});
          if (hasOnlyNonNullNodes(data)) {
            return { data };
          }
          return Promise.reject(Error('dp.getMany got a null node'));
        })
        .then(enhanceMany(resource))
        .then(res => {
          console.info('dp.getMany result' /* , res */);
          return { ...res, validUntil: new Date(Date.now() + 500) };
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          throw new HttpError(`${e.name}: ${e.message}`, '500', e.stack);
        });
    },

    // Read a list of resources related to another one
    getManyReference: (
      resource: ResourceKey,
      params: GetManyReferenceParams,
    ): Promise<GetManyReferenceResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('genericGetManyReference', {
        resource,
        resourceInfos,
        params,
      });

      return Promise.reject(
        Error('dp.genericGetManyReference is not yet implemented'),
      );
    },

    // Create a single resource
    create: async (
      resource: ResourceKey,
      params: CreateParams,
    ): Promise<CreateResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.create', { resource /* resourceInfos, params */ });

      const { data } = params as { data: NodeInput };
      const dataInput = transformCreatedData(data, resourceInfos);

      const {
        mutations: { create, transactionnal },
      } = resourceInfos;
      if (!create) {
        return Promise.reject(
          Error(
            `Resource of type ${resourceInfos.type.name} have no create mutation available`,
          ),
        );
      }
      const { name, dataInputTypename } = create;

      const mCreateName = name;
      const mType = `${dataInputTypename}!`;
      const mTransParam = transactionnal ? ', transaction: BEGIN' : '';
      const mutation = gql`
        mutation create($data: ${mType}) {
          ${mCreateName}(data: $data${mTransParam}) {
            ...NodeUpdateFragment
          }
        }
        ${getNodeUpdateFragment(resourceInfos, false)}
      `;

      const variables: CreateNodeVariables = {
        data: dataInput,
      };

      await checkAuth();

      const fetchPolicy =
        resource !== 'cpretBorrower' ? 'no-cache' : mutationFetchPolicy;
      return apolloClient
        .mutate<MutationCreateNode>({
          mutation,
          variables,
          update:
            fetchPolicy === 'no-cache'
              ? undefined
              : async (store, response) => {
                  if (response.data) {
                    const nodeUpdate = response.data[mCreateName];
                    await updateStore(
                      projectInfos.resourcesInfos,
                      nodeUpdate,
                      apolloClient,
                    );
                  }
                },
          fetchPolicy,
          refetchQueries: false as any, // https://github.com/apollographql/apollo-client/issues/10238
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dp.create did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const { node } = response.data[mCreateName];
          if (node === null) {
            return Promise.reject(Error('dp.create got a null node'));
          }
          return {
            data: node,
          };
        })
        .then(res => {
          console.info('dp.create result' /* , res */);
          if (mutationFetchPolicy === 'no-cache') return res;
          // otherwise return full data
          return baseDataProvider.getOne(resource, { id: res.data.id });
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          throw new HttpError(`${e.name}: ${e.message}`, '5000', e.stack);
        });
    },

    // Update a single resource
    update: async (
      resource: ResourceKey,
      params: UpdateParams,
    ): Promise<UpdateResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.update', { resource /* resourceInfos, params */ });

      const { id, data, previousData } = params as {
        id: ID;
        data: Node;
        previousData: Node;
      };

      const dataInput = transformUpdatedData(data, previousData, resourceInfos);

      // Nothing to update
      if (Object.keys(dataInput).length <= 0) {
        return Promise.resolve({ data: previousData });
      }

      const {
        mutations: { update, transactionnal },
      } = resourceInfos;
      if (!update) {
        return Promise.reject(
          Error(
            `Resource of type ${resourceInfos.type.name} have no update mutation available`,
          ),
        );
      }
      const { name, dataInputTypename } = update;

      const mUpdateName = name;
      const mType = `${dataInputTypename}!`;
      const mVersionType = `Int${transactionnal ? '!' : ''}`;
      const mTransParam = transactionnal ? ', transaction: BEGIN' : '';
      const mutation = gql`
    mutation update($id: ID!, $data: ${mType}, $version: ${mVersionType}) {
      ${mUpdateName}(id: $id, data: $data, version: $version${mTransParam}) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(resourceInfos, false)}
  `;

      const variables: UpdateNodeVariables = {
        id: `${id}`,
        data: dataInput,
      };
      if (data.version) {
        variables.version = +data.version;
        // note: $version can be null so it is not required (no need to filter it)
      }

      await checkAuth();

      const fetchPolicy =
        resource !== 'cpretBorrower' ? 'no-cache' : mutationFetchPolicy;
      return apolloClient
        .mutate<MutationUpdateNode>({
          mutation,
          variables,
          update:
            fetchPolicy === 'no-cache'
              ? undefined
              : async (store, response) => {
                  const { data: responseData } = response;
                  if (responseData) {
                    const nodeUpdate = responseData[mUpdateName];
                    await updateStore(
                      projectInfos.resourcesInfos,
                      nodeUpdate,
                      apolloClient,
                    );
                  }
                },
          fetchPolicy,
          refetchQueries: false as any, // https://github.com/apollographql/apollo-client/issues/10238
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dp.update did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const { node } = response.data[mUpdateName];
          if (node === null) {
            return Promise.reject(Error('dp.update got a null node'));
          }
          return {
            data: node,
          };
        })
        .then(res => {
          console.info('dp.update result' /* , res */);
          dataProvider.observeOne(resource, { id });
          if (mutationFetchPolicy === 'no-cache') return res;
          // otherwise return full data
          return baseDataProvider.getOne(resource, { id });
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          throw new HttpError(`${e.name}: ${e.message}`, '500', e.stack);
        });
    },

    // Update multiple resources
    updateMany: (
      resource: ResourceKey,
      params: UpdateManyParams,
    ): Promise<UpdateManyResult /* & { data?: HtNode[] } */> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.updateMany', { resource, resourceInfos, params });

      return Promise.reject(Error('dp.updateMany is not yet implemented'));
    },

    // Delete a single resource
    delete: async (
      resource: ResourceKey,
      params: DeleteParams,
    ): Promise<DeleteResult> => {
      // temporary failsafe 2022-07-10
      if (process.env.REACT_APP_ENV === 'prod')
        throw new Error('Delete disabled in production for now');

      const resourceInfos = resourcesInfos[resource];
      console.info('dp.delete', { resource, resourceInfos, params });

      const { id } = params as {
        id: ID;
      };

      const {
        mutations: { delete: deleteMutation },
      } = resourceInfos;
      if (!deleteMutation) {
        return Promise.reject(
          Error(
            `Resource of type ${resourceInfos.type.name} have no delete mutation available`,
          ),
        );
      }

      const { name } = deleteMutation;

      const mDeleteName = name;
      const mutation = gql`
    mutation delete($id: ID!) {
      ${mDeleteName}(id: $id) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(resourceInfos, true)}
  `;

      const variables: DeleteNodeVariables = {
        id: `${id}`,
      };

      const fetchPolicy =
        resource !== 'cpretBorrower' ? 'no-cache' : mutationFetchPolicy;
      await checkAuth();
      return apolloClient
        .mutate<MutationDeleteNode>({
          mutation,
          variables,
          update:
            fetchPolicy === 'no-cache'
              ? undefined
              : async (store, response) => {
                  if (response.data) {
                    const nodeUpdate = response.data[mDeleteName];
                    await updateStore(
                      projectInfos.resourcesInfos,
                      nodeUpdate,
                      apolloClient,
                    );
                  }
                },
          fetchPolicy,
          refetchQueries: false as any, // https://github.com/apollographql/apollo-client/issues/10238
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dp.delete did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const { node } = response.data[mDeleteName];

          if (node === null) {
            return Promise.reject(Error('dp.delete got a null node'));
          }
          return {
            data: node,
          };
        })
        .then(enhanceOne)
        .then(res => {
          console.info('dp.delete result' /* , res */);
          return res;
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          throw new HttpError(`${e.name}: ${e.message}`, '500', e.stack);
        });
    },

    // Delete multiple resources
    deleteMany: async (
      resource: ResourceKey,
      params: DeleteManyParams,
    ): Promise<DeleteManyResult> => {
      const resourceInfos = resourcesInfos[resource];
      console.info('dp.deleteMany', { resource, resourceInfos, params });

      const { ids } = params as {
        ids: ID[];
      };

      const {
        mutations: { delete: deleteMutation },
      } = resourceInfos;
      if (!deleteMutation) {
        return Promise.reject(
          Error(
            `Resource of type ${resourceInfos.type.name} have no delete mutation available`,
          ),
        );
      }

      const { name } = deleteMutation;

      const mDeleteName = name;

      const mutations = ids.reduce(
        (acc: string, id, index) =>
          acc.concat(`
        ${mDeleteName}${index}: ${mDeleteName}(id: "${id}") {
          ...NodeUpdateFragment
        }
      `),
        '',
      );

      const mutation = gql`
    mutation deleteMany {
      ${mutations}
    }
    ${getNodeUpdateFragment(resourceInfos, true)}
  `;

      await checkAuth();
      return apolloClient
        .mutate<MutationDeleteNode>({
          mutation,
          update:
            mutationFetchPolicy === 'no-cache' || resource !== 'cpretBorrower'
              ? undefined
              : async (store, response) => {
                  if (response.data) {
                    const nodeUpdates = Object.values(response.data);
                    for (const nodeUpdate of nodeUpdates) {
                      await updateStore(
                        projectInfos.resourcesInfos,
                        nodeUpdate,
                        apolloClient,
                      );
                    }
                  }
                },
          fetchPolicy: mutationFetchPolicy,
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dp.deleteMany did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data);
          return {
            data: responseData.map(({ id }) => id),
          };
        })
        .then(res => {
          console.info('dp.deleteMany result' /* , res */);
          return res;
        })
        .catch(e => {
          console.error(e);
          if (e.graphqlErrors) {
            throw new HttpError(
              e.graphqlErrors[0].message,
              e.status,
              e.grapqlErrors,
            );
          }
          throw new HttpError(`${e.name}: ${e.message}`, '500', e.stack);
        });
    },
    getClient: () => {
      return apolloClient;
    },
  };

  dataProvider = baseDataProvider as CustomDataProvider;

  // extend more
  dataProvider.getObservable = (resource: ResourceKey) =>
    getObservable(resource, environmentLevel, apolloClient, setLoading);
  dataProvider.digestEvict = (id: Identifier) => {
    return digestCache.delete(id);
  };

  dataProvider = withObserver(
    dataProvider,
    // apolloClient,
    // environmentLevel,
    // UpdateFragmentsBase,
    // updateStore,
  );

  return dataProvider;
};
