import React from 'react';
import { Filter, List, ListProps, TextInput } from 'react-admin';

import { BorrowerDatagrid } from './BorrowerDatagrid';
import PaginationWithLoader from '../../components/layout/PaginationWithLoader';

const BorrowerFilter: React.FC<unknown> = props => {
  return (
    <Filter {...props}>
      <TextInput label="Nom" source="contact.familyName" alwaysOn />
    </Filter>
  );
};

export const BorrowerList: React.FC<ListProps> = props => {
  return (
    <List
      {...props}
      filters={<BorrowerFilter />}
      bulkActionButtons={false}
      pagination={<PaginationWithLoader />}
    >
      <BorrowerDatagrid mainList />
    </List>
  );
};
