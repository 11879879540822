import React, { cloneElement } from 'react';
import {
  CreateButton,
  Filter,
  List,
  ListProps,
  SelectInput,
  TextInput,
  TopToolbar,
  useListContext,
  useResourceContext,
} from 'react-admin';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Breadcrumb } from '../../components/Breadcrumb';
import { OwnerDataGrid } from './OwnerDatagrid';
import { OwnerExportButtons } from './OwnerExports';
import PaginationWithLoader from '../../components/layout/PaginationWithLoader';
import { GuaranteeExportButtons } from '../guarantee/GuaranteeExports';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    type: {
      minWidth: 200,
    },
  }),
);

const OwnerListTitle: React.FC = () => {
  const fragments = React.useMemo(() => [], []);

  return <Breadcrumb fragments={fragments} />;
};

const OwnerFilter: React.FC<unknown> = props => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  const showCaution = filterValues?.$$cpretType === 'CAUTION';
  const showPret = filterValues?.$$cpretType === 'PRET';

  const statuses = [
    { id: 'ACTIF', name: 'ACTIF' },
    { id: 'NON ACTIF', name: 'NON ACTIF' },
  ];
  const cpretType = [
    { id: 'PRET', name: 'PRET' },
    { id: 'CAUTION', name: 'CAUTION' },
  ];
  const allOrNothing = [{ id: 0, name: 'TOUS' }];
  return (
    <Filter {...props}>
      <SelectInput
        label="Prêt ou Caution"
        source="$$cpretType"
        choices={cpretType}
        alwaysOn
        options={{ className: classes.type }}
      />
      <TextInput label="Immatriculation" source="sncfCP" alwaysOn />
      <TextInput label="Nom" source="contact.familyName" alwaysOn />
      <TextInput label="Prénom" source="contact.givenName" />
      <SelectInput
        label="Statut"
        source="$$workStatus"
        choices={statuses}
        alwaysOn
      />
      <SelectInput
        label="Prêt Honneur"
        source="$$honneurLoanCount_gt"
        choices={allOrNothing}
        defaultValue={0}
      />
      <SelectInput
        label="Autre Prêt"
        source="$$otherLoanCount_gt"
        choices={allOrNothing}
        defaultValue={0}
      />
      <SelectInput
        label="Caution"
        source="$$guaranteeCount_gt"
        choices={allOrNothing}
        defaultValue={0}
      />
      <SelectInput
        label="En cours"
        source="$$cpretActive_gt"
        choices={allOrNothing}
        defaultValue={0}
      />
      {(showPret || filterValues?.$$surrendettement_gt !== undefined) && (
        <SelectInput
          label="Surendettement"
          source="$$surrendettement_gt"
          choices={allOrNothing}
          defaultValue={0}
        />
      )}
      {(showPret || filterValues?.$$contentieux_gt !== undefined) && (
        <SelectInput
          label="Contentieux"
          source="$$contentieux_gt"
          choices={allOrNothing}
          defaultValue={0}
        />
      )}
      {(showPret || filterValues?.$$impaye_gt !== undefined) && (
        <SelectInput
          label="Impayes"
          source="$$impaye_gt"
          choices={allOrNothing}
          defaultValue={0}
        />
      )}
      {(showPret || filterValues?.$$retractation_gt !== undefined) && (
        <SelectInput
          label="Rétractation"
          source="$$retractation_gt"
          choices={allOrNothing}
          defaultValue={0}
        />
      )}
      {(showCaution || filterValues?.$$guarantiesCalled_gt !== undefined) && (
        <SelectInput
          label="Appel en garantie"
          source="$$guarantiesCalled_gt"
          choices={allOrNothing}
          defaultValue={0}
        />
      )}
      {(showCaution || filterValues?.$$ownerCessation_gt !== undefined) && (
        <SelectInput
          label="Cessation"
          source="$$ownerCessation_gt"
          choices={allOrNothing}
          defaultValue={0}
        />
      )}
    </Filter>
  );
};

// from https://github.com/marmelab/react-admin/blob/v3.16.3/packages/ra-ui-materialui/src/list/ListActions.tsx
const ListActions = (props: any) => {
  const { className, filters, ...rest } = props;
  const { basePath, displayedFilters, filterValues, showFilter } =
    useListContext(props);
  const resource = useResourceContext(rest);
  return (
    <TopToolbar className={className}>
      {cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <OwnerExportButtons />
      <GuaranteeExportButtons />
    </TopToolbar>
  );
};

export const OwnerList: React.FC<ListProps> = props => {
  return (
    <List
      {...props}
      filters={<OwnerFilter />}
      title={<OwnerListTitle />}
      bulkActionButtons={false}
      actions={<ListActions />}
      pagination={<PaginationWithLoader />}
    >
      <OwnerDataGrid />
    </List>
  );
};
