import {
  Button,
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import {
  TextInput,
  SelectInput,
  ReferenceArrayField,
  DateInput,
  required,
  // useVersion,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Record } from 'ra-core';
import { pick, get } from 'lodash';
import { useFormState } from 'react-final-form';
import LockIcon from '@material-ui/icons/Lock';
import AddIcon from '@material-ui/icons/Add';

import { Borrower } from '../../types/schema';
import FormRow from '../../components/baseElements/FormRow';
import FormSection from '../../components/baseElements/FormSection';
import { LoanDatagrid } from '../loan/LoanDatagrid';
import TooltipRGPD from '../../components/Tooltip/TooltipRGPD';
import { Ledgers } from './Ledgers';
import { ScheduleDatagrid } from './ScheduleDatagrid';
import { LedgerActionDialog } from './LedgerActionDialog';
import { Assets } from '../../components/Fields/asset/Assets';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { PostmailDialog } from '../shared/PostmailDialog';
import { useLedger } from './use-ledgers';

interface CreateRelatedLoanButtonProps {
  record?: Borrower;
  disabled?: boolean;
}

const CreateRelatedLoanButton: React.FC<CreateRelatedLoanButtonProps> = ({
  record,
  disabled,
}) => {
  return (
    <Button
      component={Link}
      disabled={disabled}
      color="default"
      size="medium"
      variant="contained"
      to={{
        pathname: '/cpretLoan/create',
        search: `?source=${JSON.stringify({
          sncfCP: get(record, 'owner.edges[0].node.sncfCP', null),
          borrower: {
            edges: [{ node: pick(record, ['id']) }],
          },
          contacts: [
            get(record, 'owner.edges[0].node.contact', null),
            get(record, 'contact'),
          ],
        })}`,
      }}
    >
      <AddIcon />
      <span>Ajouter un prêt</span>
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row',
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    leftSide: {
      flex: '1 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'column nowrap',

        '& $field': {
          width: '100%',
        },
      },
    },
    rightSide: {
      flex: '3 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'row wrap',

        '& $field': {
          flexGrow: 1,
          flexBasis: '26%', // More that 1/4
        },
      },
    },
    formSection: {},
    field: {},
    ledgerActionDialog: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    createLoanButton: {
      marginTop: theme.spacing(2),
    },
    ledgers: {
      flexFlow: 'column',
      width: '100%',
    },
  }),
);

// props injected by RA
export interface BorrowerFormProps {
  kind?: 'create' | 'edit';
  record?: Borrower;
  resource?: string;
}

// taken from generateInput.tsx - improve ?
const inputOrNull = (input: any) => input || null;

export const BorrowerForm: React.FC<BorrowerFormProps> = props => {
  const { record, resource, kind = 'edit' } = props;
  const classes = useStyles();
  const isEditView = kind === 'edit';

  const { id } = record || {};
  const loanIds = record?.loans?.edges.map(e => e.node.id);

  const [ledgers, loadingLedgers] = useLedger(id, (record as any).$$cacheId);
  const formState = useFormState();

  return (
    <div className={classes.root}>
      <section className={classes.leftSide}>
        <FormSection>
          <FormRow>
            <TooltipRGPD>
              <TextInput
                label="Commentaires"
                source="comment"
                multiline
                rows={10}
                rowsMax={10}
                fullWidth
              />
            </TooltipRGPD>
          </FormRow>
          {isEditView && (
            <FormRow>
              <Assets
                displayNamespaceSelectOnMailFile
                namespace={ASSET_USER_NAMESPACE}
                label="Pièces jointes"
                record={record}
                resource={resource}
                source="attachments"
                fullWidth
              />
            </FormRow>
          )}
        </FormSection>
      </section>
      <section className={classes.rightSide}>
        <FormSection title="Demandeur">
          <FormRow>
            <TextInput
              source="owner.edges[0].node.sncfCP"
              label="Immatriculation"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              source="owner.edges[0].node.contact.honorificPrefix"
              label="Civilité"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              format={(e: 'MME' | 'MR') => {
                return { MME: 'Madame', MR: 'Monsieur' }[e];
              }}
            />
            <TextInput
              source="owner.edges[0].node.contact.familyName"
              label="Nom usuel"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              source="owner.edges[0].node.contact.patronymicName"
              label="Nom patronymique"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              source="owner.edges[0].node.contact.givenName"
              label="Prénom"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection title="Co-emprunteur">
          <FormRow>
            <SelectInput
              source="contact.honorificPrefix"
              label="Civilité"
              choices={[
                { id: 'MME', name: 'Madame' },
                { id: 'MR', name: 'Monsieur' },
              ]}
              allowEmpty={isEditView}
              validate={isEditView ? [] : [required()]}
            />
            <TextInput
              source="contact.familyName"
              label="Nom usuel"
              validate={isEditView ? [] : [required()]}
            />
            <TextInput
              source="contact.patronymicName"
              label="Nom patronymique"
            />
            <TextInput
              source="contact.givenName"
              label="Prénom"
              validate={isEditView ? [] : [required()]}
            />
          </FormRow>
          <FormRow>
            <TextInput source="contact.email" label="Email" />
            <TextInput source="contact.tel" label="Téléphone" />
            <DateInput
              source="contact.bday"
              label="Date de naissance"
              parse={inputOrNull}
              format={inputOrNull}
              validate={isEditView ? [] : [required()]}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contact.streetAddress"
              label="Adresse"
              multiline
              rowsMax={2}
            />
            <TextInput source="contact.postalCode" label="CP" />
            <TextInput source="contact.addressLevel2" label="Ville" />
          </FormRow>
        </FormSection>
        {isEditView && (
          <ReferenceArrayField
            reference="cpretLoan"
            source="loanIds"
            addLabel={false}
            record={{ loanIds } as unknown as Record}
            fullWidth
          >
            <FormSection title="Les prêts">
              <FormRow>
                <LoanDatagrid type={record?.loanType} />
              </FormRow>
              <>
                <div className={classes.createLoanButton}>
                  <CreateRelatedLoanButton
                    record={record}
                    disabled={!formState.pristine}
                  />
                </div>
              </>
            </FormSection>
          </ReferenceArrayField>
        )}
        {isEditView && (
          <FormSection title="Informations bancaires (Prélèvement)">
            <FormRow>
              <TextInput source="umr" label="RUM" />
              <SelectInput
                source="pmtDay"
                label="Date de prélèvement "
                choices={[
                  { id: 1, name: '1' },
                  { id: 15, name: '15' },
                ]}
              />
            </FormRow>
          </FormSection>
        )}
        {isEditView && (
          <FormSection title="Comptes">
            <FormRow classes={{ content: classes.ledgers }}>
              <Ledgers key={id} ledgers={ledgers} loading={loadingLedgers} />
            </FormRow>
            <div className={classes.ledgerActionDialog}>
              <LedgerActionDialog
                ledgers={ledgers}
                disabled={loadingLedgers || !formState.pristine}
              />
            </div>
          </FormSection>
        )}
        {isEditView && record && (
          <FormSection title="Courriers">
            <PostmailDialog record={record} templatePrefix="borrower" />
          </FormSection>
        )}
        {record && isEditView && (
          <FormSection title="Echéanciers">
            <ScheduleDatagrid />
          </FormSection>
        )}
      </section>
    </div>
  );
};
