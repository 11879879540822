import {
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import React from 'react';
import {
  TextInput,
  ReferenceArrayField,
  DateInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import { Record } from 'ra-core';
import LockIcon from '@material-ui/icons/Lock';
import { useFormState } from 'react-final-form';

import { Guarantee } from '../../types/schema';
import FormRow from '../../components/baseElements/FormRow';
import FormSection from '../../components/baseElements/FormSection';
import TooltipRGPD from '../../components/Tooltip/TooltipRGPD';
import { Assets } from '../../components/Fields/asset/Assets';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { PostmailDialog } from '../shared/PostmailDialog';
import { GuarantyLoanDatagrid } from '../guarantyLoan/GuarantyLoanDatagrid';
import { GuaranteeSummaryDialog } from './GuaranteeSummaryDialog';
import { GuaranteeDetailsDialog } from './GuaranteeDetailsDialog';
import { useIsAdmin } from '../../hooks/use-is-admin';
import { GuaranteeActionDialog } from './GuaranteeActionDialog';
import { CurrencyInput } from '../loan/CurrencyFields';
import { ActionHistory } from '../common/ActionHistory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row',
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    leftSide: {
      flex: '1 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'column nowrap',

        '& $field': {
          width: '100%',
        },
      },
    },
    rightSide: {
      flex: '3 0 0%',
      '& $formSection': {
        display: 'flex',
        flexFlow: 'row wrap',

        '& $field': {
          flexGrow: 1,
          flexBasis: '26%', // More that 1/4
        },
      },
    },
    formSection: {},
    field: {},
    guaranteeActionDialog: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    createLoanButton: {
      marginTop: theme.spacing(2),
    },
    nonDenonciableText: {
      lineHeight: '41px',
      marginLeft: 4,
      cursor: 'pointer',
    },
    nonDenonciableInput: {
      flexGrow: 0,
      marginRight: '1em',
    },
  }),
);

// props injected by RA
export interface GuaranteeFormProps {
  record?: Guarantee;
  resource?: string;
}

// taken from generateInput.tsx - improve ?
const inputOrNull = (input: any) => input || null;
const disableOn = (condition: boolean) => ({
  disabled: condition,
  InputProps: condition
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <LockIcon />
          </InputAdornment>
        ),
      }
    : {},
});

export const GuaranteeForm: React.FC<GuaranteeFormProps> = props => {
  const { record: guarantee, resource } = props;
  const classes = useStyles();
  const formState = useFormState();

  const loanIds = guarantee?.loans?.edges.map(e => e.node.id);

  const isAdmin = useIsAdmin();

  return (
    <div className={classes.root}>
      <section className={classes.leftSide}>
        <FormSection>
          <FormRow>
            <TooltipRGPD>
              <TextInput
                label="Commentaires"
                source="comment"
                multiline
                rows={10}
                rowsMax={10}
                fullWidth
              />
            </TooltipRGPD>
          </FormRow>

          <FormRow>
            <Assets
              displayNamespaceSelectOnMailFile
              namespace={ASSET_USER_NAMESPACE}
              label="Pièces jointes"
              record={guarantee}
              resource={resource}
              source="attachments"
              fullWidth
            />
          </FormRow>
        </FormSection>
      </section>
      <section className={classes.rightSide}>
        <FormSection title="Demandeur" className={classes.formSection}>
          <FormRow>
            <TextInput
              source="owner.edges[0].node.sncfCP"
              label="Immatriculation"
              {...disableOn(true)}
            />
            <TextInput
              source="owner.edges[0].node.contact.honorificPrefix"
              label="Civilité"
              {...disableOn(true)}
              format={(e: 'MME' | 'MR') => {
                return { MME: 'Madame', MR: 'Monsieur' }[e];
              }}
            />
            <TextInput
              source="owner.edges[0].node.contact.familyName"
              label="Nom usuel"
              {...disableOn(true)}
            />
            <TextInput
              source="owner.edges[0].node.contact.givenName"
              label="Prénom"
              {...disableOn(true)}
            />
          </FormRow>
        </FormSection>
        <FormSection title="Demandeur à date" className={classes.formSection}>
          <FormRow>
            <TextInput
              source="sncfCP"
              label="Immatriculation"
              {...disableOn(!isAdmin)}
            />
            {isAdmin ? (
              <SelectInput
                source="contacts[0].honorificPrefix"
                label="Civilité"
                choices={[
                  { id: 'MME', name: 'Madame' },
                  { id: 'MR', name: 'Monsieur' },
                ]}
                allowEmpty
              />
            ) : (
              <TextInput
                source="contacts[0].honorificPrefix"
                label="Civilité"
                {...disableOn(true)}
                format={(e: 'MME' | 'MR' | '') => {
                  return e ? { MME: 'Madame', MR: 'Monsieur' }[e] : '';
                }}
                defaultValue=""
                value=""
              />
            )}
            <TextInput
              source="contacts[0].familyName"
              label="Nom usuel"
              {...disableOn(!isAdmin)}
            />
            <TextInput
              source="contacts[0].patronymicName"
              label="Nom patronymique"
              {...disableOn(!isAdmin)}
            />
            <TextInput
              source="contacts[0].givenName"
              label="Prénom"
              {...disableOn(!isAdmin)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contacts[0].email"
              label="Email"
              {...disableOn(!isAdmin)}
            />
            <TextInput
              source="contacts[0].tel"
              label="Téléphone"
              {...disableOn(!isAdmin)}
            />
            <DateInput
              source="contacts[0].bday"
              label="Date de naissance"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(!isAdmin)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contacts[0].streetAddress"
              label="Adresse"
              multiline
              rowsMax={2}
              {...disableOn(!isAdmin)}
            />
            <TextInput
              source="contacts[0].postalCode"
              label="CP"
              {...disableOn(!isAdmin)}
            />
            <TextInput
              source="contacts[0].addressLevel2"
              label="Ville"
              {...disableOn(!isAdmin)}
            />
          </FormRow>
        </FormSection>
        {guarantee?.contacts && guarantee.contacts.length > 1 ? (
          <FormSection title="Co-emprunteur à date">
            <FormRow>
              <TextInput source="coSncfCP" label="Immatriculation" />
              <SelectInput
                source="contacts[1].honorificPrefix"
                label="Civilité"
                choices={[
                  { id: 'MME', name: 'Madame' },
                  { id: 'MR', name: 'Monsieur' },
                ]}
                allowEmpty
              />
              <TextInput source="contacts[1].familyName" label="Nom usuel" />
              <TextInput
                source="contacts[1].patronymicName"
                label="Nom patronymique"
              />
              <TextInput source="contacts[1].givenName" label="Prénom" />
            </FormRow>
            <FormRow>
              <TextInput source="contacts[1].email" label="Email" />
              <TextInput source="contacts[1].tel" label="Téléphone" />
              <DateInput
                source="contacts[1].bday"
                label="Date de naissance"
                parse={inputOrNull}
                format={inputOrNull}
              />
            </FormRow>
            <FormRow>
              <TextInput
                source="contacts[1].streetAddress"
                label="Adresse"
                multiline
                rowsMax={2}
              />
              <TextInput source="contacts[1].postalCode" label="CP" />
              <TextInput source="contacts[1].addressLevel2" label="Ville" />
            </FormRow>
          </FormSection>
        ) : null}

        <FormSection title="Projet">
          <FormRow>
            <DateInput
              source="signature"
              label="Date de signature"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(guarantee?.signature !== undefined && !isAdmin)}
            />
            <CurrencyInput
              source="amount"
              label="Montant cautionné" /* disabled */
              {...disableOn(!isAdmin)}
            />
          </FormRow>{' '}
        </FormSection>

        <FormSection title="Les cautions (prêts cautionnés)">
          <FormRow>
            <ReferenceArrayField
              reference="cpretGuarantyLoan"
              source="loanIds"
              addLabel={false}
              record={{ loanIds } as unknown as Record}
              fullWidth
            >
              <GuarantyLoanDatagrid />
            </ReferenceArrayField>
          </FormRow>
          <div className={classes.guaranteeActionDialog}>
            <GuaranteeActionDialog disabled={!formState.pristine} />
          </div>
        </FormSection>

        <FormSection title="Courriers">
          <PostmailDialog
            record={guarantee as Guarantee}
            templatePrefix="guarantee"
          />
        </FormSection>

        <FormSection title="Synthèse instruction">
          <GuaranteeSummaryDialog record={guarantee as Guarantee} />
        </FormSection>

        <FormSection title="Détails instruction">
          <GuaranteeDetailsDialog record={guarantee as Guarantee} />
        </FormSection>

        <FormSection title="Gestion">
          <FormRow>
            <FormGroup>
              <Typography
                variant="body1"
                className={classes.nonDenonciableText}
                component="label"
                htmlFor="nonDenunciation"
              >
                Non-dénonciable
              </Typography>
            </FormGroup>
            <BooleanInput
              className={classes.nonDenonciableInput}
              source="nonDenunciation"
              label="" /* forces null in FieldTitle */
              helperText={false}
            />
          </FormRow>
          <FormHelperText>
            L'indicateur Cessation ne sera plus remonté si cette option est
            activée
          </FormHelperText>
        </FormSection>
        {isAdmin ? (
          <FormSection title="Activité">
            <ActionHistory type="Guarantee" />
          </FormSection>
        ) : null}
      </section>
    </div>
  );
};
