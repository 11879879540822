import React from 'react';

import {
  AgnosticSelectField,
  AgnosticSelectFieldProps,
} from '../../../components/Fields/agnostic/AgnosticSelectField';

export type ActionTypeFieldProps = Omit<
  AgnosticSelectFieldProps,
  'options' | 'name'
>;

export enum LedgerActionType {
  DISBURSEMENT = 'DISBURSEMENT',
  REPAYMENT = 'REPAYMENT',
  UNPAID_CHECK = 'UNPAID_CHECK',
  REJECTED_BANK_TRANSFER = 'REJECTED_BANK_TRANSFER',
  DELAYED_SCHEDULE_REPAYMENT = 'DELAYED_SCHEDULE_REPAYMENT',
  EXCEDENT_REPAYMENT_BY_BANK_TRANFER = 'EXCEDENT_REPAYMENT_BY_BANK_TRANFER',
  PRINCIPAL_ROUNDING = 'PRINCIPAL_ROUNDING',
  USER_ROUNDING = 'USER_ROUNDING',
  PRINCIPAL_USER_DEBIT = 'PRINCIPAL_USER_DEBIT',
  LOSSES_USER_CREDIT = 'LOSSES_USER_CREDIT',
  CUSTOM_TRANSACTION = 'CUSTOM_TRANSACTION',
}

const options = [
  { label: 'Versement des fonds', value: LedgerActionType.DISBURSEMENT },
  { label: 'Remboursement anticipé', value: LedgerActionType.REPAYMENT },
  { label: 'Retour chèque impayé', value: LedgerActionType.UNPAID_CHECK },
  {
    label: 'Prélèvement bancaire rejeté',
    value: LedgerActionType.REJECTED_BANK_TRANSFER,
  },
  {
    label: 'Remboursement échéance de retard',
    value: LedgerActionType.DELAYED_SCHEDULE_REPAYMENT,
  },
  {
    label: 'Enregistrement trop perçu par virement',
    value: LedgerActionType.EXCEDENT_REPAYMENT_BY_BANK_TRANFER,
  },
  {
    label: 'Régularisation arrondis fin de prêt',
    value: LedgerActionType.PRINCIPAL_ROUNDING,
  },
  {
    label: 'Régularisation arrondis compte agent',
    value: LedgerActionType.USER_ROUNDING,
  },
  {
    label: 'Déchéance du terme',
    value: LedgerActionType.PRINCIPAL_USER_DEBIT,
  },
  {
    label: 'Abandon de créance (irrécouvrable)',
    value: LedgerActionType.LOSSES_USER_CREDIT,
  },
  {
    label: 'Evénement exceptionnel',
    value: LedgerActionType.CUSTOM_TRANSACTION,
  },
];

export function formatActionType(actionType: LedgerActionType) {
  return options.find(o => o.value === actionType)?.label;
}

export const ActionTypeField: React.FC<ActionTypeFieldProps> = props => {
  return (
    <AgnosticSelectField
      {...props}
      options={options}
      name="actionType"
      label="Type de mouvement"
    />
  );
};
