import React, { useCallback } from 'react';
import {
  Button,
  //   DataProviderProxy,
  useDataProvider,
  //   useListContext,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';

import { stringify } from 'csv-stringify/dist/esm/sync';
import { format } from 'date-fns';

import { EnhancedBorrower } from '../../types/schema-custom';
import { Loan, ScheduleType } from '../../types/schema';
import { centsToEur } from '../../lib/utils';
import download from '../../lib/download';
import { allActivePayments } from '../../workflow/lib/gpg';
import { useAppContext } from '../../context/AppContext';

const exportFilename = (now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-cpret-export.csv`;

const COLS = [
  'ID Prêt',
  'Immatriculation Demandeur',
  'Nom Demandeur',
  'Prénom Demandeur',
  'Adresse Demandeur',
  'CP Demandeur',
  'Ville Demandeur',
  'ID Bénéficiaire',
  'Nom Bénéficiaire',
  'Prénom Bénéficiaire',
  'Nature du prêt',
  'Statut du prêt',
  'Montant du prêt',
  'Date signature du prêt',
  'Solde compte prêt mois m',
  'Solde compte agent mois m',
  'date 1ère échéance (échéancier primaire)',
  'date dernière échéance (échéancier primaire)',
  'Nb de mois',
  'Date 1ère échéance (échéancier secondaire)',
  'Date dernière échéance (échéancier secondaire)',
  'Nb de mois',
  'Total dû',
  'Total échéances',
  'RUM',
  'Date de prélèvement',
];

const brwrsToCsv = async (bb: EnhancedBorrower[]): Promise<string[][]> => {
  const output: string[][] = [];
  bb.forEach(b => {
    const loans = b.loans?.edges.map(({ node }) => node as Loan);
    const o = b.owner?.edges[0]?.node;
    const { principalPmt: p, userPmt: u } = allActivePayments(b); // ignore interests
    const willPay = p + u;
    const debt = b.$$principalBalance + b.$$userBalance;

    const capSchedule = b.schedules?.filter(
      s => s.type === ScheduleType.CAPITAL,
    );
    const dbtSchedule = b.schedules?.filter(s => s.type === ScheduleType.DEBT);

    const capMap = new Map<string, boolean>();
    capSchedule?.forEach(s => {
      s.payments?.forEach(pmt => {
        capMap.set(pmt.dueDate.slice(0, 7), true);
      });
    });
    const capMonths = [...capMap.keys()].sort((ma, mb) => ma.localeCompare(mb));
    const dbtMap = new Map<string, boolean>();
    dbtSchedule?.forEach(s => {
      s.payments?.forEach(pmt => {
        dbtMap.set(pmt.dueDate.slice(0, 7), true);
      });
    });
    const dbtMonths = [...dbtMap.keys()].sort((ma, mb) => ma.localeCompare(mb));

    loans.forEach(l => {
      output.push(
        [
          l.id,
          o?.sncfCP,
          o?.contact?.familyName,
          o?.contact?.givenName,
          o?.contact?.streetAddress,
          o?.contact?.postalCode,
          o?.contact?.addressLevel2,
          b.id,
          b.contact?.familyName,
          b.contact?.givenName,
          b.loanType,
          l.wfStatus,
          centsToEur(l.amount).toFixed(2),
          l.signature,
          centsToEur(b.$$principalBalance).toFixed(2),
          centsToEur(b.$$userBalance).toFixed(2),
          capMonths[0],
          capMonths[capMonths.length - 1],
          capMonths.length,
          dbtMonths[0],
          dbtMonths[dbtMonths.length - 1],
          dbtMonths.length,
          centsToEur(debt).toFixed(2),
          centsToEur(-willPay).toFixed(2),
          b.umr,
          b.pmtDay,
        ].map(s => s || ''),
      );
    });
  });
  return output;
};

export const OwnerExportButtons: React.FC = () => {
  const dataProvider = useDataProvider();
  const { loading } = useAppContext();

  const handleExportAll = useCallback(() => {
    const action = async () => {
      const { data } = await dataProvider.getList<EnhancedBorrower>(
        'cpretBorrower',
        {
          filter: {},
          pagination: { page: 0, perPage: -1 },
          sort: { field: 'createdAt', order: 'ASC' },
        },
      );
      //   const bbAlerts = data.filter(d => d.$$scheduleAlert > 0);
      const csvData = await brwrsToCsv(data);
      const out = stringify(csvData, {
        header: true,
        delimiter: ';',
        columns: COLS,
      });
      download(Buffer.from(out, 'latin1'), exportFilename());
    };
    return action();
  }, [dataProvider]);

  return (
    <Button onClick={handleExportAll} label="Exp. Prêts" disabled={!!loading}>
      <DownloadIcon />
    </Button>
  );
};
