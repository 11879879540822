import { Theme, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Color from 'color';
import React from 'react';
import { ArrayField, Button, Datagrid } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { cloneDeep, omit, sortBy } from 'lodash';
import { addMonths, format } from 'date-fns';

import { FirstPaymentDueDate } from './field/FirstPaymentDueDate';
import { LastPaymentDueDate } from './field/LastPaymentDueDate';
import { PaymentStatus } from './field/PaymentStatus';
import { PrincipalAmountLeft } from './field/PrincipalAmountLeft';
import { PrincipalAmount } from './field/PrincipalAmount';
import { PaymentNextMonthlyAmount } from './field/PaymentNextMonthlyAmount';
import { PaymentProgress } from './field/PaymentProgress';
import { Reason } from './field/Reason';
import { Style } from '../../../types/styles';
import {
  Schedule,
  ScheduleType,
  Ledger,
  Borrower,
} from '../../../types/schema';
import { CreateScheduleDialog } from './CreateScheduleDialog/CreateScheduleDialog';
import { EditScheduleDialog } from './EditScheduleDialog';
import { Type } from './field/Type';
import { IndexedSchedule } from './types';

function cleanSchedule(schedule: IndexedSchedule) {
  return omit(
    {
      ...schedule,
      payments: (schedule.payments || []).map(p => omit(p, ['isNewRecord'])),
    },
    ['index'],
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createButton: {
      marginTop: theme.spacing(1),
    },
  }),
);

export const ScheduleDatagrid: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { values } = useFormState();
  const { id, ledger: ledgerConnection } = values as Borrower;
  const schedules = (values as Borrower).schedules || []; // to handle null and undefined values;

  const ledgers = React.useMemo(
    () => ledgerConnection?.edges?.map(e => e.node as Ledger) || [],
    [ledgerConnection],
  );

  // We redefine a record for the array input with index added to schedules
  const rootRecord = React.useMemo(
    () => ({
      id,
      schedules: sortBy(
        schedules.map(
          (v, i) => ({
            index: i,
            ...v,
          }),
          'payments[0].dueDate',
        ),
      ).reverse() as IndexedSchedule[],
    }),
    [id, schedules],
  );

  const form = useForm();
  const [rowIndex, setRowIndex] = React.useState<number | null>(null);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const toggleCreateDialog = React.useCallback(
    () => setShowCreateDialog(!showCreateDialog),
    [showCreateDialog],
  );

  const latestDueDate = React.useMemo(
    () =>
      (schedules as Schedule[]).reduce((maxDueDate, schedule) => {
        if (!maxDueDate) {
          if (!schedule.payments) {
            return null;
          }

          return new Date(schedule.payments.slice(-1)[0].dueDate);
        }

        const moreRecentPayment = schedule.payments?.find(
          payment => new Date(payment.dueDate) > maxDueDate,
        );
        return moreRecentPayment
          ? new Date(moreRecentPayment.dueDate)
          : maxDueDate;
      }, null as Date | null) || new Date(),
    [schedules],
  );
  const hasCapitalSchedule = React.useMemo(
    () => (schedules as Schedule[]).find(s => s.type === ScheduleType.CAPITAL),
    [schedules],
  );

  // we register schedules field to ensure changes update form state flags
  React.useEffect(() => {
    const unsubscribe = form.registerField('schedules', () => {}, {});
    return unsubscribe;
  }, [form]);

  const onRowClick = React.useCallback(
    (_: any, __: any, record: IndexedSchedule) => {
      const index = rootRecord.schedules.findIndex(
        schedule => schedule.index === record.index,
      );
      setRowIndex(index);
    },
    [rootRecord],
  ) as any;

  const onDialogCommit = React.useCallback(
    (schedule: IndexedSchedule) => {
      form.change(`schedules.${schedule.index}`, cleanSchedule(schedule));
      setRowIndex(null);
    },
    [form],
  );
  const onDialogRollback = React.useCallback(() => {
    setRowIndex(null);
  }, []);

  const onDialogDelete = React.useCallback(() => {
    const clone = cloneDeep(rootRecord.schedules);
    clone.splice(rowIndex as number, 1);
    form.change(
      'schedules',
      clone.map(d => cleanSchedule(d)),
    );
    setRowIndex(null);
  }, [rowIndex, form, rootRecord]);

  const onCreate = React.useCallback(
    async (newSchedule: Schedule) => {
      form.change('schedules', [...schedules, newSchedule]);
      toggleCreateDialog();
    },
    [form, schedules, toggleCreateDialog],
  );

  const rowStyle = React.useCallback(
    (_, index: number): Style => {
      return {
        backgroundColor:
          index % 2
            ? 'transparent'
            : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
      };
    },
    [theme],
  );

  const createDialogInitialValues = React.useMemo(
    () => ({
      startDate: format(
        latestDueDate ? addMonths(new Date(latestDueDate), 1) : new Date(),
        'yyyy-MM-dd',
      ),
      type: hasCapitalSchedule ? ScheduleType.DEBT : ScheduleType.CAPITAL,
      amount: 0,
    }),
    [latestDueDate, hasCapitalSchedule],
  );

  return (
    <>
      {showCreateDialog && (
        <CreateScheduleDialog
          ledgers={ledgers}
          initialValues={createDialogInitialValues}
          onCommit={onCreate}
          onRollback={toggleCreateDialog}
        />
      )}
      {rowIndex !== null && (
        <EditScheduleDialog
          onCommit={onDialogCommit}
          onDelete={onDialogDelete}
          onRollback={onDialogRollback}
          schedule={rootRecord.schedules[rowIndex]}
        />
      )}
      <ArrayField source="schedules" record={rootRecord}>
        <Datagrid rowStyle={rowStyle} rowClick={onRowClick}>
          <Type label="Type" />
          <FirstPaymentDueDate label="1er Mois de prélèvement" />
          <LastPaymentDueDate label="Fin échéancier" />
          <PaymentStatus label="Etat" />
          <PrincipalAmountLeft label="Capital restant dû" />
          <PrincipalAmount label="Capital total" />
          <PaymentNextMonthlyAmount label="Montant de la prochaine échéance" />
          <PaymentProgress label="N° Echéance / total éch." />
          <Reason label="Motif" />
        </Datagrid>
      </ArrayField>
      <Button
        color="default"
        size="medium"
        className={classes.createButton}
        variant="contained"
        label="Ajouter un échéancier"
        onClick={toggleCreateDialog}
      >
        <AddIcon />
      </Button>
    </>
  );
};
