import { AuthProvider } from 'ra-core';
import gravatar from 'gravatar';
import { LoginType, SncfLogin, RefreshableCredentials } from 'phileog-login';
import {
  AwsCognito,
  getAwsCredentials,
  Instance,
  Level,
  Provider,
} from 'phicomas-client';

// TODO - Add "local" to Level !
let appEnv = Level.DEV;
if (process.env.REACT_APP_ENV === 'prod') appEnv = Level.PROD;
else if (process.env.REACT_APP_ENV === 'stage') appEnv = Level.STAGE;
export const level = process.env.NODE_ENV !== 'production' ? Level.DEV : appEnv;

const googleAuth: AwsCognito = {
  provider: Provider.Google,
  assumedRole: {
    [Level.STAGE]: 'arn:aws:iam::738246919441:role/Content_Cpret_Admin_Auth',
    [Level.PROD]: 'arn:aws:iam::000844313570:role/Content_Cpret_Admin_Auth',
  },
};

const sncfAuth: AwsCognito = {
  provider: Provider.SNCF,
  loginEnv: {
    [Level.DEV]:
      process.env.NODE_ENV !== 'production'
        ? 'us-east-1:cpret-local'
        : 'us-east-1:cpret-dev',
    [Level.STAGE]: 'eu-west-1:cpret-stage',
    [Level.PROD]: 'eu-west-1:cpret-prod',
  },
};

export const awsCognito: Parameters<typeof getAwsCredentials>[0] = {
  [Instance.BACK]:
    process.env.NODE_ENV !== 'production' ? googleAuth : sncfAuth,
};

export const awsEnv: Parameters<typeof getAwsCredentials>[1] = {
  instance: Instance.BACK,
  level,
};

/** @todo - pass awsCognito[awsEnv.instance] directly ! */
export const awsCreds = getAwsCredentials(
  awsCognito, // Always login phileadmin using Google provider
  awsEnv,
);

const getAuthProvider = (): AuthProvider => {
  if (!(awsCreds instanceof RefreshableCredentials)) {
    throw new Error('Not a RefreshableCredential');
  }
  let state = awsCreds.getState();
  awsCreds.subject.subscribe(s => {
    state = s;
  });
  awsCreds.retry();

  return {
    // called when the user attempts to log in
    login: async (): Promise<any> => {
      await awsCreds.login(
        awsCreds.provider instanceof SncfLogin ? LoginType.POPUP : undefined,
      );
    },
    // called when the user clicks on the logout button
    logout: async (): Promise<void> => {
      await awsCreds.logout();
      return undefined; // may return route string
    },
    // called when the API returns an error
    // On reject, calls logout
    checkError: (error: any): Promise<void> => {
      const { status } = error;
      if (status === 401 || status === 403) {
        awsCreds.logout();
        return Promise.reject();
      }
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve();
    },
    // called when the user navigates to a new location
    // On reject, calls logout
    checkAuth: async (): Promise<any> => {
      if (state.loading) await state.loading;
      if (awsCreds.needsRefresh()) {
        await new Promise<void>(resolve => {
          const sub = awsCreds.subject.subscribe(s => {
            if (s.error) {
              resolve(Promise.reject(/* { redirectTo: '/login' } */));
            } else {
              resolve();
            }
            sub.unsubscribe();
          });
          awsCreds.retry();
        });
      }
      if (state.user) return undefined;
      throw new Error('Auth failed');
    },
    getIdentity: async (): Promise<any> => {
      if (state.loading) await state.loading;
      const claims = state.user;
      if (claims) {
        const { sub, givenName, familyName, email } = claims;
        const fullName = givenName ? `${givenName} ${familyName}` : sub;
        return {
          id: sub,
          fullName,
          avatar: email && gravatar.url(email),
        };
      }
      return {
        id: '',
        fullName: null,
      };
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async (): Promise<any[]> => {
      if (state.loading) await state.loading;
      const claims = state.user;
      if (claims) {
        const { roles } = claims; // company will be here
        return roles || [];
      }
      return [];
    },
  };
};

export default getAuthProvider;
